import "core-js/modules/es.array.push.js";
// import routes from '../router/routes'

export default {
  name: 'kplist',
  data() {
    return {
      showAll: false,
      showPopover: false,
      showAction: false,
      actions: [{
        text: '选项一'
      }, {
        text: '选项二'
      }, {
        text: '选项三'
      }],
      activeNames: ['1'],
      studentInfo: {
        idCard: '000000000000000000'
      },
      kplist: [],
      planTemp: {}
    };
  },
  methods: {
    onSelect(data) {
      console.log(data);
      console.log('select');
      console.log(JSON.stringify(data));
      this.$router.push({
        path: 'kpxx',
        state: data
      });
    },
    getmytask() {
      var that = this;
      that.$http.get('/api/article/list', null, null).then(result => {
        console.log(result);
        if (result.success) {
          that.kplist = result.data;
        } else {
          that.kplist = [];
          that.$toast(result.msg);
          //this.showQYWXCode();
        }
      });
    },

    goTest(scale) {
      this.$router.push({
        path: './testDo',
        query: {
          scaleId: scale.id,
          planId: this.planTemp.id
        }
      });
    }
  },
  created() {},
  mounted() {
    var userstr = localStorage.getItem('user');
    var user = JSON.parse(userstr);
    this.studentInfo = user.studentInfo;
    this.getmytask();
  }
};